.header-top-text-pc {
  color: #2a58ff;
}
.box-main-title {
  justify-content: center;
  margin-bottom: 20px;
}
.pick_date {
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.pick_date .ant-picker {
  height: 40px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .css-14x55nd {
    width: 96% !important;
    padding: 32px 25px !important;
    .css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
      padding-left: 15px;
    }
    .css-1ld3b9g-MuiGrid-root {
      margin-left: -27px !important;
    }
  }
}
