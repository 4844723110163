.box-button-auth {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  span {
    width: 70%;
    background-color: #2a58ff;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    color: #fff;
    border-radius: 10px;
  }
}
