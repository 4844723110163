@use "../../../../assets/common/utilities/mixins" as mixin;

.header-body {
  // @include mixin.flex(center, space-between);
  display: flex;
  height: 105px;
  grid-template-columns: 1.4fr 0.7fr;
  // grid-template-columns: 0.7fr 0.7fr;
  // padding: 0 33px;
  &-home {
    @include mixin.flex(center, space-between);
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .header-body {
    &-home {
      @include mixin.flex(center);
    }
  }
}
.wrapper-header-body {
  display: flex;
  margin: 20px;
  position: sticky;
  .lg-search {
    margin-left: 30px;
    display: flex;
    width: calc(100% - 1%);
    .img-logo {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
    }
  }
}
@media screen and (max-width: 480px) {
  .wrapper-header-body {
    display: unset;
    margin: 0;
    position: sticky;
    .lg-search {
      padding-bottom: 12px;
      margin-left: 0;
      display: unset;
      width: calc(100% - 1%);
      .img-logo {
        width: unset;
        height: unset;
        min-width: 100px;
        min-height: 100px;
      }
    }
  }
}
