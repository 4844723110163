@use "../../../../assets/common/utilities/mixins" as mixin;
.header-dropdown {
  float: right;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .header-dropdown-favorits {
    img {
      width: 26px;
      height: 26px;
    }
  }
  .header-dropdown-bag {
    margin-left: 22px;
  }
  .header-dropdown-profile {
    margin-right: 22px;
    margin-left: 22px;
    position: relative;
    z-index: 111111111;
    padding: 12px 0 10px 0;
  }
  .header-dropdown-profile:hover {
    .profile-login {
      // display: block;
      transition: 0.5s;
      transform: translateX(-150px);
    }
  }
}
.header-top {
  @include mixin.flex(center, space-between);
  height: 40px;
  // background-color: var(--blue-footer);
  color: #737373;

  &-text {
    @include mixin.flex(center, space-between);
    padding: 0px 21px;
    width: calc(100% - 183px);
    p {
      height: 100%;
      font-size: 12px;
      font-weight: 500;
      // color: white;
    }
    &-mobile {
      display: none;
    }
    &-pc {
      margin-top: 12px;
    }
  }

  .header-top-language {
    height: 100%;
    // background-color: #fa6f34;
    // display: flex;
    // align-items: center;
    // @include mixin.flex(center);
    padding-top: 12px;
    font-size: 14px;
    font-weight: 500;
    #auth {
      display: flex;
      inline-size: max-content;
      gap: 10px;
      margin-right: 5px;
      span {
        padding: 0 10px 0 0;
        border-right: 1px solid #737373;
        cursor: pointer;
      }
    }
    #language {
      width: 103px;
      color: #737373;
      // background-color: #369313;
      border: none;
      height: 100%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .header-top-text-pc {
    display: flex !important;
  }
  .header-top-text-mobile {
    display: block;
  }
}
