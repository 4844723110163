.App {
  text-align: center;
  height: auto;
  
}
.slick-list{
  width: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.carousel-detail-iframe {
  width: 98%;
  height: 279px;
  margin: 0 60px 0 36;

  .slick-slider{
    width: 100%;
    margin: auto auto auto 1.4%;
    gap: 10px ;
    display: flex;
    align-items: center;
  }
}
.slick-list{
  margin-left: 30px
}
.card-carousel-iframe {
  padding: 0 10px 0 10px;
  /* display: grid;
  justify-content: center; */


}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

