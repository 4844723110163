.auth-login {
  margin-right: 25px;
  &-btn {
    border-radius: 25px;
    height: 59px;
    width: 232px;
    background-color: white;
    border: 1px solid var(--orange-home);
    font-size: 22px;
    cursor: pointer;
    padding-top: 12px;
    color: var(--orange-home);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.MuiBox-root {
  .box-main-login-normal {
    display: flex;
    justify-content: flex-end;
    .box-main-login-cancel {
      background: #dadada;
      cursor: pointer;
      margin-right: 13px;
      height: 40px;
      width: 86px;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      padding-top: 7px;
      border-radius: 6px;
    }
  }
  .box-main-login-social {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 24px;
  }
  .box-main-login {
    width: 100%;
    height: 80%;
    margin-top: -25px;
    padding: 0px 5%;

    .box-main-title {
      p {
        font-size: 24px;
        font-weight: 500;
      }
      width: 100%;
      height: 25px;
      display: flex;
    }
    .box-main-form {
      input {
        width: 100%;
        padding-left: 15px;
        height: 40px;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
      }
      .box-main-form-forget {
        color: var(--orange-home);
      }
    }
    .box-forgot {
      display: flex;
      justify-content: space-between;
      padding: 10px 40px;
      .remember {
        display: flex;
        gap: 8px;
      }
    }
    .box-main-or {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      margin-top: 20px;
    }
  }
  .box-forgot {
    .head-form-forgot {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-left: 22px;
      .icon-back {
        cursor: pointer;
        border: 2px solid #2a58ff;
        padding: 0px 6px 3px 6px;
        border-radius: 50%;
      }
      .text-back {
        font-size: 16px;
        .text {
          cursor: pointer;
          color: #2a58ff;
        }
      }
    }
    .form-input-forgot {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .error {
        color: red;
      }
      input {
        width: 500px;
        display: block;
        outline: none;
        border: none;
        height: 2em;
        font-size: 16px;
        margin-bottom: 1px;
        border-bottom: 1px solid #2a58ff;
      }
    }
    .title {
      margin-top: 48px;
      display: flex;
      justify-content: center;
    }
    .title p {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 480px) {
  .MuiBox-root .box-forgot .form-input-forgot {
    input {
      width: 80%;
    }
    small {
      padding: 8px;
    }
  }
  .css-yfkv1u {
    width: 90% !important;
    height: unset !important;
    .box-main-login {
      .title-login {
        margin: 32px 12px !important;
      }
    }
  }
  .css-91q775 {
    width: 90% !important;
    height: unset !important;
    .box-main-login {
      .box-main-form {
        .box-register {
          padding: 40px 8px !important;
        }
      }
    }
  }
}
