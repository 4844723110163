.footer {
  background-color: #efefef;
  padding-top: 20px;
  .body-footer {
    text-align: left;
    margin: 30px auto;
    width: 85%;
    .title {
      font-size: 22px;
      font-weight: 500;
      padding-bottom: 8px;
    }
    p {
      margin-bottom: 4px;
    }
  }
}
