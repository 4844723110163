.header-nav {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  color: white;
  background-color: #2a58ff;
  padding: 15px;
  .nav_text {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  a {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  //a:hover {
  //  color: #7edf61;
  //  border-bottom: 3px solid #7edf61;
  //}

  &-menu-mb {
    display: none;
  }
  .header-link {
    border-bottom: 3px solid #2a58ff;
  }
  .header-link:hover {
    color: #7edf61;
    border-bottom: 3px solid #7edf61;
    .nav_text {
      color: #7edf61;
    }
  }
}

.header-nav-menu-mb {
  display: none;
}

.header-link {
  // font-weight: 500;
  font-weight: bold;
  padding: 4px;
  a {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  .header-nav {
    // margin: 0px;
    margin-left: 10px;
    display: flex;
    justify-content: space-around;
  }
  .MuiButtonBase-root {
    svg {
      font-size: 30px;
    }
  }
  .header-nav-menu-mb {
    display: none;
    justify-content: flex-end;
    align-items: flex-end;
    // margin-left: 30px;
  }
}

.header-dropdown-menu {
  margin-right: 22px;
  margin-left: 22px;
  position: relative;
}
.header-dropdown-menu:hover {
  .parent {
    display: block;
    transition: 0.5s;
    transform: translateX(0px);
  }
}

@media screen and (max-width: 480px) {
  .header-nav {
    margin-left: 0;
    margin-top: 12px;
    display: grid;
    padding: 8px;
    .header-link {
      span {
        font-size: 12px;
      }
    }
  }
}
